import React from 'react';
import Banner from '../banner/Banner';
import HappyClinet from '../../img/kupci.png';
import Geer from '../../img/preradjene-tone.png';
import Vehicle from '../../img/vozila.png';
import Workers from '../../img/radnici.png';
import WomenWorker from '../../img/about-first.jpg';
import BannerBcg from '../../img/about.jpg';

function PlaceOfGoodPackages() {
	return (
		<div id="about" className="good-packages">
			<div className="container">
				<div className="good-packages__image">
					<img src={WomenWorker} alt="Radnica za mašinom" />
				</div>
				<div className="good-packages__text">
					<h2>
						<span>Mjesto </span>dobrih pakovanja
					</h2>
					<div className="bottom">
						<p>
							<strong>Laki Plast</strong> je osnovan u Banjaluci, 2002. godine i
							vremenom je izrastao u lidera za proizvodnju i preradu LDPE i HDPE
							komercijalnih folija, te folija za domaćinstvo, godišnjeg
							kapaciteta 1000 tona. U 2021. godini uvodi i preradu papira za
							pekarstvo i mesarstvo, 500 tona na godišnjem nivou.
						</p>
						<p>
							Iz skladišta gotovih proizvoda, sopstvenim vozilima svakodnevno
							distribuiramo trgovačke vrećice, vrećice sa banana ručkom,
							polagajuće folije za poljoprivredu, termoskupljajuće folije, kao i
							asortiman za domaćinstva: Alu i prozirne folije, vreće za smeće,
							vrećice za zamrzivač, kao i trake za fiskalne kase.
						</p>
						<p>
							Aktuelna tehnologija i vrhunska sirovina garantuju odličan
							proizvod, prihvatljivu cijenu i zadovoljnog kupca.
						</p>
					</div>
				</div>
			</div>
			<Banner classVal="good-packages__list" bcgImage={BannerBcg}>
				<ul>
					<li>
						<div className="icon">
							<img
								src={HappyClinet}
								alt="Zadovoljni kolijeti ikonica"
								width="89"
							/>
						</div>
						<div className="text">
							<p>920</p>
							<p>zadovoljan klijent</p>
						</div>
					</li>
					<li>
						<div className="icon">
							<img src={Geer} alt="Prerađene tone ikonica" width="89" />
						</div>
						<div className="text">
							<p>1280</p>
							<p>prerađenih tona</p>
						</div>
					</li>
					<li>
						<div className="icon">
							<img src={Vehicle} alt="Dostavna vozila ikonica" width="89" />
						</div>
						<div className="text">
							<p>8</p>
							<p>dostavnih vozila</p>
						</div>
					</li>
					<li>
						<div className="icon">
							<img src={Workers} alt="Radnik ikonica" width="89" />
						</div>
						<div className="text">
							<p>18</p>
							<p>radnika</p>
						</div>
					</li>
				</ul>
			</Banner>
		</div>
	);
}

export default PlaceOfGoodPackages;
