export function getFromStorage() {
	let products;
	if (localStorage.getItem('products') === null) {
		products = [];
	} else {
		products = JSON.parse(localStorage.getItem('products'));
	}
	return products;
}
export function addToStorage(product) {
	let products = getFromStorage();
	products.push(product);

	localStorage.setItem('products', JSON.stringify(products));
}

export function clearStorage(products) {
	localStorage.removeItem(products);
}
