import React from 'react';

function CategoryItem(props) {
  return (
    <div className="category">
      <div className="category__image">
        <img src={props.source} alt={props.alt} />
      </div>
      <div className="category__content">
        <div className="text-content">{props.children}</div>
      </div>
    </div>
  );
}

export default CategoryItem;
