import React from 'react';

function Map() {
	return (
		<div className="map">
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.741868288803!2d17.1675807158637!3d44.84718998242081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475e03acb24f07bb%3A0x4b585a6817dca04f!2sLaki%20Plast!5e0!3m2!1sbs!2shu!4v1630846153540!5m2!1sbs!2shu"
				style={{ border: 0 + 'px' }}
				allowFullScreen=""
				loading="lazy"
				title="Laki plast mapa"
			></iframe>
		</div>
	);
}

export default Map;
