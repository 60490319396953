import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ActionPopup = () => {
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState(null);
	useEffect(() => {
		axios
			.get('https://lakiplast.com/laki/wp-json/wp/v2/posts/1')
			.then((res) => {
				setData(res.data);
			})
			.then(() => {
				setTimeout(() => {
					setIsActive(true);
				}, 2000);
			})
			.catch((err) => {
				console.log(err);
				setIsActive(false);
			});
		return () => {
			setIsActive(false);
		};
	}, []);
	const content =
		data !== null ? (
			<article>
				<button
					className="clear"
					onClick={() => {
						setIsActive(false);
					}}
				>
					X
				</button>
				<div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
				<div dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }} />
				<a href={data.title.rendered}>Prikaži kategoriju sa popustom</a>
			</article>
		) : null;
	const popUp =
		data !== null && data.title.rendered !== 'Nema akcija' ? (
			<div className={`action${isActive ? ' active' : ''}`}>{content}</div>
		) : null;
	return popUp;
};

export default ActionPopup;
