import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './componets/navbar/NavBar';
import Contact from './componets/pages/Contact';
import Home from './componets/pages/Home';
import Shop from './componets/pages/Shop';
import Footer from './componets/footer/Footer';
import Cart from './componets/pages/Cart';
function App() {
	return (
		<Router>
			<div className="App">
				<NavBar />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/prodavnica" component={Shop} />
					<Route exact path="/prodavnica/:id" component={Shop} />
					<Route exact path="/izabrani-proizvodi" component={Cart} />
					<Route exact path="/kontakt" component={Contact} />
				</Switch>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
