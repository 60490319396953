import React from 'react';
import { NavLink } from 'react-router-dom';

function Categories() {
	return (
		<div className="categories">
			<ul>
				<li>
					<NavLink exact to="/prodavnica">
						Sve
					</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/19">Treger kese</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/21">Kese za zamrzivač</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/22">Celofan za tegle sa gumicama</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/23">Vreće za smeće</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/24">Vreće za smeće u rolni</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/25">Vreće za smeće sa trakom</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/27">Aluminijumske folije</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/28">Prozirne streč folije</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/43">Ading rolne</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/31">Termax</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/33">Butik kese</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/34">Poklon kese</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/36">Pekarska ambalaža</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/37">Mesarska ambalaža</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/39">Industrijski najloni</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/40">Najlonske vreće</NavLink>
				</li>
				<li>
					<NavLink to="/prodavnica/42">Kese za novogodišnje paketiće</NavLink>
				</li>
			</ul>
		</div>
	);
}

export default Categories;
