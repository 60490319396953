import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Player from '../player/Player';
//import PlayButton from '../../img/white-play-button-transparent-8.png';
import PlaceOfGoodPackages from '../place-of-good-packages/PlaceOfGoodPackages';
import Categories from '../categories/Categories';
import Banner from '../banner/Banner';
import ActionPopup from '../action-popup/ActionPopup';
import VideoBcg from '../../img/slajder.jpg';
import Posvecenost from '../../img/posvecenost.png';
import Distribucija from '../../img/distribucija.png';
import EkoOdgovornost from '../../img/ekologija.png';
import Video01 from '../../video/laki.mp4';
import Video02 from '../../video/laki_2.mp4';

function Home() {
	const [state, setState] = useState({
		email: '',
		subject: '',
		msg: '',
	});
	const [winWidth, setWinWidth] = useState(0);
	const changeHandler = (ev) => {
		const target = ev.target;
		const name = ev.name;
		setState({
			...state,
			[name]: target.value,
		});
		console.log(state);
	};
	useEffect(() => {
		setWinWidth(window.innerWidth);
	}, []);

	window.addEventListener('resize', () => {
		setWinWidth(window.innerWidth);
	});
	return (
		<>
			<Helmet>
				<title>Laki Plast - Naslovna</title>‍
				<meta name="description" content="Neki tekst za description" />
				<meta property="og:title" content="Laki Plast - Naslovna" />
				<meta property="og:description" content="Neki tekst za description" />
				<meta property="og:image" content="" />
				<meta property="og:url" content="lakiplast.com" />
				<meta property="og:site_name" content="Laki Plast - Naslovna" />
				<meta property="og:locale" content="sr_BA" />
				<meta property="og:type" content="website" />
			</Helmet>
			<div className="home">
				<header id="home">
					<Player url={winWidth > 1440 ? Video01 : Video02} controls={false} />
					<div className="text-content">
						<h1>
							Vlastita
							<br />
							proizvodnja
						</h1>
						<p>
							polietilenskih LDPE
							<br />i HDPE folija
						</p>
					</div>
				</header>

				<PlaceOfGoodPackages />
				<div className="what-we-do">
					<div className="container">
						<div className="what-we-do-container">
							<div className="what-we-do-item">
								<img src={Posvecenost} alt="Posvećenost kupcu" />
								<h3>
									Posvećenost
									<br /> kupcu
								</h3>
								<p>
									Osim standardnih proizvoda, Laki Plast je u mogućnosti da
									personalizuje svoje proizvode za potrebe klijenata i tržišta:
									od razvoja novih pakovanja, pa to vrhunske četverobojne flekso
									štampe.
								</p>
								<p>
									Svjesni veličine i ambijenta tržišta, spremni smo da ponudimo
									optimalan odnos tiraža, kvaliteta i cijene.
								</p>
							</div>
							<div className="what-we-do-item">
								<img src={Distribucija} alt="Distribucija" />
								<h3>Distribucija</h3>
								<p>
									Vlastitim vozilima vršimo svakodnevnu distribuciju na cijeloj
									teritoriji Bosne i Hercegovine, a po potrebi i u regionu.
								</p>
							</div>
							<div className="what-we-do-item">
								<img src={EkoOdgovornost} alt="Ekološka odgovornost" />
								<h3>
									Ekološka
									<br /> odgovornost
								</h3>
								<p>
									Polietilenska LDPE i HDPE folija se proizvodi direktno iz
									granulata, kod čije nabavke se rukovodimo ekološkim principima
									i posjedujemo sertifikat za biorargradive kese d2W.
								</p>
							</div>
						</div>
					</div>
				</div>
				<Categories />
				<Banner classVal="home-contact" bcgImage={VideoBcg}>
					<div className="home-contact-container">
						<div className="home-contact-item">
							<div className="title">
								<h2>Pošaljite upit</h2>
							</div>
						</div>
						<div className="home-contact-item">
							<div className="contact-row">
								<div className="contact-label">
									<label htmlFor="email">Vaša e-mail adresa:</label>
								</div>
								<div className="contact-fielad">
									<input
										type="email"
										name="email"
										value={state.email}
										id="email"
										onChange={changeHandler}
									/>
								</div>
							</div>
							<div className="contact-row">
								<div className="contact-label">
									<label htmlFor="subject">Predmet:</label>
								</div>
								<div className="contact-fielad">
									<input
										type="text"
										name="subject"
										value={state.subject}
										id="subject"
										onChange={changeHandler}
									/>
								</div>
							</div>
							<div className="contact-row">
								<div className="contact-label">
									<label htmlFor="msg">Poruka:</label>
								</div>
								<div className="contact-fielad">
									<textarea
										name="msg"
										value={state.msg}
										id="msg"
										onChange={changeHandler}
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</Banner>
			</div>
			<ActionPopup />
		</>
	);
}

export default Home;
