import React from 'react';
import { NavLink } from 'react-router-dom';
import FooterLogo from '../../img/laki-logo-bijeli.png';
import Info from '../info/Info';
import Location from '../../img/adresa.png';
import Phone from '../../img/telefon.png';
import Email from '../../img/email.png';
import Wa from '../../img/whatup.png';
/*
import Fb from '../../img/fb.png';
import In from '../../img/in.png';
*/

function Footer() {
	return (
		<footer>
			<div className="container">
				<div className="footer-item">
					<img src={FooterLogo} alt="Logo Bijeli Laki Plast" />
				</div>
				<Info
					classVal="footer-item"
					iconLocation={Location}
					iconPhone={Phone}
					iconEmail={Email}
					iconWhatsapp={Wa}
				/>
				<div className="footer-item">
					<ul>
						<li>
							<NavLink to="/prodavnica/treger-kese">Treger kese</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/kese-za-zamrzivac">
								Kese za zamrzivač
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/celofan-za-tegle-sa-gumicama">
								Celofan za tegle sa gumicama
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/vrece-za-smece">Vreće za smeće</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/vrece-za-smece-u-rolni">
								Vreće za smeće u rolni
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/vrece-za-smece-sa-trakom">
								Vreće za smeće sa trakom
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/aluminijske-folije">
								Aluminijumske folije
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/prozirne-strec-folije">
								Prozirne streč folije
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/ading-rolne">Ading rolne</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/termax">Termax</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/butik-kese">Butik kese</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/poklon-kese">Poklon kese</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/pekarska-ambalaza">
								Pekarska ambalaža
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/mesarska-ambalaza">
								Mesarska ambalaža
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/industrijski-najloni">
								Industrijski najloni
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/najlonske-vrece">
								Najlonske vreće
							</NavLink>
						</li>
						<li>
							<NavLink to="/prodavnica/kese-za-novogodisnje-paketice">
								Kese za novogodišnje paketiće
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
