import React from 'react';

function Product(props) {
	return (
		<div className="product-item">
			<div className="product-image">
				<img src={props.image} alt={props.imageAlt} />
			</div>
			<div className="product-content">
				<h3>{props.title}</h3>
				<ul>
					<li>{props.dimension}</li>
					<li>Pakovanje: {props.package}</li>
					<li>EAN: {props.eanCode}</li>
					<li>{props.price}</li>
				</ul>
				<div className="add-to-cart">
					<button
						className="btn add-to-cart-btn"
						aria-label="Add to cart button"
						onClick={props.addProduct}
					>
						<span>DODAJ U KORPU</span>
					</button>
					<div className="qty">
						<button className="inc" onClick={props.incOnClick}>
							+
						</button>
						<input
							type="text"
							name="quantity"
							value={props.qtValue}
							onChange={props.qtChange}
						/>
						<button className="dec" onClick={props.decOnClick}>
							-
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Product;
