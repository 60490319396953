import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../../img/logo.svg';
import Cart from '../../img/cart.png';

function NavBar() {
	const [mobBtnActive, setMobBynAcitive] = useState(false);
	const [isFixed, setIsFixed] = useState(false);

	const mobBtnHandler = () => {
		setMobBynAcitive(!mobBtnActive);
	};
	useEffect(() => {
		window.addEventListener('scroll', () => {
			let paggeYPoss = window.pageYOffset;
			const navbarTop = document.querySelector('.navbar-top');
			let heightOfNavbarTop = navbarTop ? navbarTop.clientHeight : null;
			if (paggeYPoss >= heightOfNavbarTop + 103) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
			return function () {
				window.removeEventListener('scroll');
			};
		});
	});

	return (
		<>
			<div className="navbar-top">
				<div className="container">
					<div className="navbar-top__logo">
						<Link to="/">
							<img src={Logo} alt="Logo" height="100" />
						</Link>
					</div>
				</div>
			</div>
			<nav className={`navbar${isFixed ? ' fixed-top' : ''}`}>
				<div className="container">
					<div className="navbar__mob-btn">
						<button
							type="button"
							className={mobBtnActive ? 'active' : null}
							onClick={mobBtnHandler}
						>
							<span></span>
							<span></span>
							<span></span>
						</button>
					</div>
					<div className="navbar__logo">
						<Link to="/">
							<img src={Logo} alt="Navbar Logo" height="52" />
						</Link>
					</div>
					<ul
						className={`navbar__collapse-menu${mobBtnActive ? ' active' : ''}`}
					>
						<li className="navbar__nav-item" onClick={mobBtnHandler}>
							<NavLink exact to="/" className="navbar__nav-link">
								Naslovna
							</NavLink>
						</li>
						<li className="navbar__nav-item" onClick={mobBtnHandler}>
							<HashLink
								smooth
								to="/#about"
								className="navbar__nav-link"
								scroll={(el) =>
									el.scrollIntoView({ behavior: 'auto', block: 'start' })
								}
							>
								O nama
							</HashLink>
						</li>
						<li className="navbar__nav-item" onClick={mobBtnHandler}>
							<HashLink smooth to="/#production" className="navbar__nav-link">
								Proizvodnja
							</HashLink>
						</li>
						<li className="navbar__nav-item" onClick={mobBtnHandler}>
							<NavLink to="/prodavnica" className="navbar__nav-link">
								Prodavnica
							</NavLink>
						</li>
						<li className="navbar__nav-item" onClick={mobBtnHandler}>
							<NavLink to="/kontakt" className="navbar__nav-link">
								Kontakt
							</NavLink>
						</li>
					</ul>
					<div className="navbar__cart">
						<img
							src={Cart}
							alt="Cart icon"
							height="34.5"
							className="navbar__cart-icon"
						/>
						<Link to="/izabrani-proizvodi" className="btn navbar__cart-link">
							<span>MOJA KORPA</span>
							<span>
								<img src={Cart} alt="Cart icon" height="34.5" />
							</span>
						</Link>
					</div>
				</div>
			</nav>
		</>
	);
}

export default NavBar;
