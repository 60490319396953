import React from 'react';
import { Link } from 'react-router-dom';
import CategoryItem from '../category-item/CategoryItem';
import TregerKese from '../../img/tregerice.jpg';
import KeseZaZamrzivsac from '../../img/zamrzivac.jpg';
import VreceZaSmece from '../../img/vrece-za-smece.jpg';
import AluFolije from '../../img/alu-folija.jpg';
import AdingRolne from '../../img/ading-rolne.jpg';
import PoklonKese from '../../img/butik-kese.jpg';
import PekarskaAmbalaza from '../../img/pekarski.jpg';
import Industrijski from '../../img/industrijiski.jpg';
import Pokloni from '../../img/pokloni.jpg';

function Categories() {
	return (
		<div id="production">
			<div className="container">
				<div className="title">
					<h2>
						<span>Vlastita </span>proizvodnja
					</h2>
				</div>
				<div className="production-cetegories">
					<CategoryItem source={TregerKese} alt="Treger Kese">
						<h3>
							<Link to="/prodavnica/19">Treger Kese</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={KeseZaZamrzivsac} alt="Kese Za Zamrzivsac">
						<h3>
							<Link to="/prodavnica/21">Kese za zamrzivač</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/22">Celofan za tegle sa gumicama</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={VreceZaSmece} alt="Vreće za smeće">
						<h3>
							<Link to="/prodavnica/23">Vreće za smeće</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/24">Vreće za smeće u rolni</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/25">Vreće za smeće sa trakom</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={AluFolije} alt="Aluminijumske folije">
						<h3>
							<Link to="/prodavnica/27">Aluminijumske folije</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/28">Prozirne streč folije</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={AdingRolne} alt="Ading rolne">
						<h3>
							<Link to="/prodavnica/43">Ading rolne</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/31">Termax</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={PoklonKese} alt="Butik kese">
						<h3>
							<Link to="/prodavnica/33">Butik kese</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/34">Poklon kese</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={PekarskaAmbalaza} alt="Pekarska ambalaža">
						<h3>
							<Link to="/prodavnica/36">Pekarska ambalaža</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/37">Mesarska ambalaža</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={Industrijski} alt="Industrijski najloni">
						<h3>
							<Link to="/prodavnica/39">Industrijski najloni</Link>
						</h3>
						<h3>
							<Link to="/prodavnica/40">Najlonske vreće</Link>
						</h3>
					</CategoryItem>
					<CategoryItem source={Pokloni} alt="Kese za novogodišnje paketiće">
						<h3>
							<Link to="/prodavnica/42">Kese za novogodišnje paketiće</Link>
						</h3>
					</CategoryItem>
				</div>
			</div>
		</div>
	);
}

export default Categories;
