import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { addToStorage, getFromStorage } from '../functions/LocalStorage';
import CategoriesMenu from '../categories-menu/CategoriesMenu';
import Product from '../product/Product';
import Search from '../../img/search.png';
import Loader from '../../img/loader.gif';
import axios from 'axios';

function Shop() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState(null);
  const [searchState, setSearchState] = useState('');

  const url =
    id === undefined
      ? 'https://lakiplast.com/laki/wp-json/wc/v3/products?consumer_key=ck_c40ee3a66cb4a924b5be7d4bb416a0b44ac5a4fb&consumer_secret=cs_6f32d33394dd19e19a1fef692f39bba3cc33c61e&per_page=80'
      : `https://lakiplast.com/laki/wp-json/wc/v3/products?category=${id}&consumer_key=ck_c40ee3a66cb4a924b5be7d4bb416a0b44ac5a4fb&consumer_secret=cs_6f32d33394dd19e19a1fef692f39bba3cc33c61e`;

  const onChangeHandler = (ev) => {
    setSearchState(ev.target.value);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(url)
      .then((res) => {
        setProducts(
          res.data.map((item) => {
            return { ...item, quantity: 1 };
          })
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    return () => {
      setLoader(false);
      setProducts(null);
    };
  }, [id, url]);

  function serchHandler(ev) {
    ev.preventDefault();
    if (searchState !== '' && products !== null) {
      const filteredProducts = products.filter((item) => {
        if (item.name.toLowerCase().includes(searchState.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      });
      setProducts(
        filteredProducts.map((item) => {
          return { ...item, quantity: 1 };
        })
      );
    } else {
      setLoader(true);
      axios
        .get(url)
        .then((res) => {
          setProducts(
            res.data.map((item) => {
              return { ...item, quantity: 1 };
            })
          );
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  }
  function productInfo(product) {
    const storage = getFromStorage('products');
    const isExist = storage.some((item) => {
      return item.id === product.id;
    });
    if (!isExist && product !== null) {
      const selectedProduct = {
        name: product.name,
        price: product.price,
        id: product.id,
        quantity: product.quantity,
        weight: product.weight,
      };
      if (selectedProduct !== null) {
        addToStorage(selectedProduct);
      } else {
        return;
      }
    } else {
      return;
    }
  }

  const productContent =
    products !== null && products.length > 0 ? (
      products.map((product, key) => {
        return (
          <Product
            key={product.id}
            image={product.images[0].src}
            imageAlt={product.name}
            title={product.name}
            dimension={
              product.dimensions.length !== ''
                ? `${product.dimensions.length} x ${product.dimensions.width} x ${product.dimensions.height}`
                : null
            }
            package={
              product.attributes.length > 0
                ? product.attributes[0].options[0]
                : null
            }
            eanCode={product.sku}
            price={`${product.price} KM`}
            qtValue={product.quantity}
            qtChange={(ev) => {
              const { name, value } = ev.target;
              const newProduct = { ...product, [name]: value };

              const newProducts = [...products];
              newProducts.splice(key, 1, newProduct);
              setProducts(newProducts);
            }}
            addProduct={productInfo.bind(this, product)}
            incOnClick={(ev) => {
              const newProduct = { ...product, quantity: product.quantity + 1 };

              const newProducts = [...products];
              newProducts.splice(key, 1, newProduct);
              setProducts(newProducts);
            }}
            decOnClick={(ev) => {
              const newProduct = {
                ...product,
                quantity: product.quantity > 1 ? product.quantity - 1 : 1,
              };

              const newProducts = [...products];
              newProducts.splice(key, 1, newProduct);
              setProducts(newProducts);
            }}
          />
        );
      })
    ) : (
      <p>U ovoj kategoriji nema poizvoda.</p>
    );
  const loaderContent = (
    <div className='loader'>
      <img src={`${Loader}`} alt='Loader' />
    </div>
  );
  return (
    <>
      <Helmet>
        <title>Laki Plast - Prodavnica</title>‍
        <meta name='description' content='Neki tekst za description' />
        <meta property='og:title' content='Laki Plast - Prodavnica' />
        <meta property='og:description' content='Neki tekst za description' />
        <meta property='og:image' content='' />
        <meta property='og:url' content='lakiplast.com' />
        <meta property='og:site_name' content='Laki Plast - Prodavnica' />
        <meta property='og:locale' content='sr_BA' />
        <meta property='og:type' content='website' />
      </Helmet>
      <div className='shop'>
        <div className='container'>
          <div className='search'>
            <form onSubmit={serchHandler}>
              <input
                type='text'
                name='search'
                value={searchState}
                placeholder='Pretraga'
                onChange={onChangeHandler}
              />
              <button type='submit'>
                <img src={Search} alt='Search ikonica' />
              </button>
            </form>
          </div>
          <div className='shop-container'>
            <CategoriesMenu />
            <div className='products'>
              {loader ? loaderContent : productContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;
