import React from 'react';

function Banner(props) {
  return (
    <div
      className={`banner ${props.classVal}`}
      style={{ backgroundImage: `url(${props.bcgImage})` }}
    >
      <div className="container">{props.children}</div>
    </div>
  );
}

export default Banner;
