import React, { useState, useEffect } from 'react';
import { getFromStorage, clearStorage } from '../functions/LocalStorage';
import axios from 'axios';

const Cart = () => {
	const [firstState, setFirstState] = useState({
		company: '',
		pib: '',
		firstName: '',
		lastName: '',
		address: '',
		city: '',
		zip: '',
		country: '',
		tel: '',
		email: '',
		differentAddress: false,
	});
	const [secState, setSecState] = useState({
		firstName: '',
		lastName: '',
		address: '',
		city: '',
		zip: '',
	});
	const [newsLetter, setNewsLetter] = useState(false);
	const [products, setProducts] = useState(null);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalWeight, setTotalWeight] = useState(0);
	const [payment, setPayment] = useState(
		'Plaćam gotovinom prilikom preuzimanja'
	);
	const [message, setMessage] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	function firstStateOnChange(ev) {
		const { name, value } = ev.target;
		setFirstState({
			...firstState,
			[name]: value,
		});
	}
	function secStateOnChange(ev) {
		const { name, value } = ev.target;
		setSecState({
			...secState,
			[name]: value,
		});
	}
	useEffect(() => {
		const items = getFromStorage();
		setProducts(items);
	}, []);
	useEffect(() => {
		const prices =
			products !== null
				? products.map((item) => {
						return item.price * item.quantity;
				  })
				: null;
		const weights =
			products !== null
				? products.map((item) => {
						return item.weight * item.quantity;
				  })
				: null;
		if (products !== null && prices.length > 0 && products.length > 0) {
			setTotalPrice(
				prices.reduce((acc, num) => {
					return acc + num;
				})
			);
		} else {
			setTotalPrice(0);
		}
		if (products !== null && weights.length > 0 && products.length > 0) {
			setTotalWeight(
				weights.reduce((acc, num) => {
					return acc + num;
				})
			);
		} else {
			setTotalWeight(0);
		}
	}, [products]);

	function formSubmitHandler(ev) {
		ev.preventDefault();
		const data = [
			{ ...firstState },
			firstState.differentAddress ? { ...secState } : null,
			{ newsLetter: newsLetter },
			[...products],
			{ totalPrice: totalPrice },
			{ totalWeight: totalWeight },
			{ payment: payment },
		];
		if (
			firstState.firstName === '' ||
			firstState.lastName === '' ||
			firstState.address === '' ||
			firstState.country === '' ||
			firstState.city === '' ||
			firstState.zip === '' ||
			firstState.tel === '' ||
			firstState.email === ''
		) {
			setMessage('Sva polja moraju biti popunjena. Hvala!');
			setIsSuccess(false);
			return;
		}
		if (
			firstState.differentAddress === true &&
			(secState.address === '' ||
				secState.city === '' ||
				secState.firstName === '' ||
				secState.lastName === '' ||
				secState.zip === '')
		) {
			setMessage('Sva polja moraju biti popunjena. Hvala!');
			setIsSuccess(false);
			console.log(data);
			return;
		}
		if (products.length === 0) {
			setMessage('Morate izabrati bar jedan proizvod. Hvala!');
			setIsSuccess(false);
			return;
		}

		axios
			.post('https://lakiplast.com/api/cart.php', data)
			.then((res) => {
				if (res.status === 200) {
					console.log(res);
					clearStorage('products');
					setProducts(null);
					setFirstState({
						company: '',
						pib: '',
						firstName: '',
						lastName: '',
						address: '',
						city: '',
						zip: '',
						country: '',
						tel: '',
						email: '',
						differentAddress: false,
					});
					setSecState({
						firstName: '',
						lastName: '',
						address: '',
						city: '',
						zip: '',
					});
					setNewsLetter(false);
					setTotalPrice(0);
					setTotalWeight(0);
					setPayment('Plaćam gotovinom prilikom preuzimanja');
					setMessage('Uspješno ste obavili kupovinu. Hvala!');
					setIsSuccess(true);
				}
			})
			.catch((err) => {
				console.log(err);
				setMessage('Nešto nije u redu. Molimo pokušajte kasnije.');
				setIsSuccess(false);
			});
	}
	function removeFromStorage(ev) {
		ev.preventDefault();
		const id = ev.target.dataset.id;
		if (products !== null) {
			const newProducts = [...products];
			const filteredProducts = newProducts.filter((product) => {
				return parseInt(product.id) !== parseInt(id);
			});
			setProducts(filteredProducts);
			localStorage.setItem('products', JSON.stringify(filteredProducts));
		} else {
			return;
		}
	}

	const productContent =
		products !== null
			? products.map((product, key) => {
					return (
						<tr key={product.id}>
							<td>
								<button
									className="del"
									aria-label="delete button"
									data-id={product.id}
									onClick={removeFromStorage}
								>
									X
								</button>
							</td>
							<td>{product.name}</td>
							<td>{product.price} KM</td>
							<td>
								<div className="cell">
									<button
										className="inc"
										onClick={(ev) => {
											ev.preventDefault();
											const newProduct = {
												...product,
												quantity: product.quantity + 1,
											};

											const newProducts = [...products];
											newProducts.splice(key, 1, newProduct);
											setProducts(newProducts);
										}}
									>
										+
									</button>
									<input
										type="text"
										name="num"
										value={product.quantity}
										readOnly
									/>
									<button
										className="dec"
										onClick={(ev) => {
											ev.preventDefault();
											const newProduct = {
												...product,
												quantity:
													product.quantity > 1 ? product.quantity - 1 : 1,
											};

											const newProducts = [...products];
											newProducts.splice(key, 1, newProduct);
											setProducts(newProducts);
										}}
									>
										-
									</button>
								</div>
							</td>
						</tr>
					);
			  })
			: null;
	return (
		<div className="cart">
			<div className="title">
				<div className="container">
					<h1>Moja korpa</h1>
				</div>
			</div>
			<div className="container">
				<div className="wrapper">
					<form onSubmit={formSubmitHandler}>
						<div className="left-side">
							<div className="info">
								<h2>Podaci za plaćanje</h2>
								<p>*samo za pravna lica</p>
								<div className="form-group">
									<div className="form-item">
										<label htmlFor="company">Naziv pravnog lica*</label>
										<input
											type="text"
											name="company"
											value={firstState.company}
											id="company"
											onChange={firstStateOnChange}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="pib">PIB*</label>
										<input
											type="text"
											name="pib"
											value={firstState.pib}
											id="pib"
											onChange={firstStateOnChange}
										/>
									</div>
								</div>
								<div className="form-group">
									<div className="form-item">
										<label htmlFor="firstName">Ime</label>
										<input
											type="text"
											name="firstName"
											value={firstState.firstName}
											id="firstName"
											onChange={firstStateOnChange}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="lastName">Prezime</label>
										<input
											type="text"
											name="lastName"
											value={firstState.lastName}
											id="lastName"
											onChange={firstStateOnChange}
										/>
									</div>
								</div>
								<div className="form-group">
									<div className="form-item">
										<label htmlFor="address">Adresa</label>
										<input
											type="text"
											name="address"
											value={firstState.address}
											id="address"
											onChange={firstStateOnChange}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="city">Grad</label>
										<input
											type="text"
											name="city"
											value={firstState.city}
											id="city"
											onChange={firstStateOnChange}
										/>
									</div>
								</div>
								<div className="form-group">
									<div className="form-item">
										<label htmlFor="zip">Poštanski broj</label>
										<input
											type="text"
											name="zip"
											value={firstState.zip}
											id="zip"
											onChange={firstStateOnChange}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="country">Država</label>
										<input
											type="text"
											name="country"
											value={firstState.country}
											id="country"
											onChange={firstStateOnChange}
										/>
									</div>
								</div>
								<div className="form-group">
									<div className="form-item">
										<label htmlFor="email">Email</label>
										<input
											type="email"
											name="email"
											value={firstState.email}
											id="email"
											onChange={firstStateOnChange}
										/>
									</div>
									<div className="form-item">
										<label htmlFor="tel">Telefon</label>
										<input
											type="text"
											name="tel"
											value={firstState.tel}
											id="tel"
											onChange={firstStateOnChange}
										/>
									</div>
								</div>
							</div>
							<div className="different-address">
								<h2>
									<span>Dostaviti na različitu adresu?</span>{' '}
									<input
										type="checkbox"
										name="differentAddress"
										value="Poslati na drugu adresu"
										defaultChecked={firstState.differentAddress}
										checked={firstState.differentAddress}
										onClick={(ev) => {
											setFirstState({
												...firstState,
												differentAddress: !firstState.differentAddress,
											});
										}}
									/>
								</h2>
								{firstState.differentAddress !== false ? (
									<>
										<div className="form-group">
											<div className="form-item">
												<label htmlFor="firstName">Ime</label>
												<input
													type="text"
													name="firstName"
													value={secState.firstName}
													id="firstName"
													onChange={secStateOnChange}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="lastName">Prezime</label>
												<input
													type="text"
													name="lastName"
													value={secState.lastName}
													id="lastName"
													onChange={secStateOnChange}
												/>
											</div>
										</div>
										<div className="form-group">
											<div className="form-item">
												<label htmlFor="address">Adresa</label>
												<input
													type="text"
													name="address"
													value={secState.address}
													id="address"
													onChange={secStateOnChange}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="city">Grad</label>
												<input
													type="text"
													name="city"
													value={secState.city}
													id="city"
													onChange={secStateOnChange}
												/>
											</div>
										</div>
										<div className="form-group">
											<div className="form-item">
												<label htmlFor="zip">Poštanski broj</label>
												<input
													type="text"
													name="zip"
													value={secState.zip}
													id="zip"
													onChange={secStateOnChange}
												/>
											</div>
										</div>
									</>
								) : null}

								<div className="newaletter">
									<h2>
										<span>Prijavljujem se na newsletter</span>{' '}
										<input
											type="checkbox"
											name="newsLetter"
											defaultChecked={newsLetter}
											checked={newsLetter}
											onClick={() => {
												setNewsLetter(!newsLetter);
											}}
										/>
									</h2>
								</div>
							</div>
						</div>
						<div className="right-side">
							<h2>Vaša narudžba</h2>
							<table>
								<thead>
									<tr>
										<th></th>
										<th>Proizvod</th>
										<th>Cijena/kom</th>
										<th>Količina</th>
									</tr>
								</thead>
								<tbody>{productContent}</tbody>
								<tfoot>
									<tr>
										<th colSpan={3}>Ukupno</th>
										<td>
											{products !== null && products.length > 0
												? totalPrice.toFixed(3)
												: 0}{' '}
											KM
										</td>
									</tr>
									<tr>
										<th colSpan={3}>Težina:</th>
										<td>
											{products !== null && products.length > 0
												? totalWeight.toFixed(3)
												: 0}{' '}
											kg
										</td>
									</tr>
								</tfoot>
							</table>
							<div className="payment">
								<label>
									<input
										type="radio"
										name="payment"
										value="Plaćam gotovinom prilikom preuzimanja"
										checked={
											payment === 'Plaćam gotovinom prilikom preuzimanja'
										}
										onChange={(ev) => {
											const { value } = ev.target;
											setPayment(value);
										}}
									/>{' '}
									Plaćam gotovinom prilikom preuzimanja
								</label>
								<label>
									<input
										type="radio"
										name="payment"
										value="Plaćam po predračunu"
										checked={payment === 'Plaćam po predračunu'}
										onChange={(ev) => {
											const { value } = ev.target;
											setPayment(value);
										}}
									/>{' '}
									Plaćam po predračunu
								</label>
							</div>
							<div className="cart-info">
								<div className="delivery">
									<p>
										Narudžbe do 100,00 KM šaljemo putem brze pošte, o trošku
										kupca.
									</p>
									<p>
										Narudžbe preko 100,00 KM i do 30 kg težine šaljemo putem
										brze pošte, o našem trošku.
									</p>
									<p>
										Za narudžbe preko 100,00 KM i preko 30 kg bićete
										obaviješteni o načinu isporuke.{' '}
									</p>
								</div>
								<p>
									Vaši ličnii podaci će biti korišteni za poboljšanje iskustva
									korištenja naše web stranice, za procesiranje vaše narudžbe i
									neće biti ustupani trećim licima.
								</p>
							</div>
							<button type="submit" aria-label="Form button" className="btn ">
								Naručujem
							</button>
							<p className={`${isSuccess ? 'success' : 'error'}`}>{message}</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Cart;
