import React from 'react';
import ReactPlayer from 'react-player/lazy';

function Player(props) {
	return (
		<div className="player-wrapper">
			<ReactPlayer
				className="react-player"
				url={props.url}
				controls={props.controls}
				playing={true}
				loop={true}
				width="100%"
				height="100%"
				volume={0}
				muted={true}
			/>
		</div>
	);
}

export default Player;
