import React from 'react';

function Info(props) {
  return (
    <div className={`info ${props.classVal}`}>
      <ul>
        <li>
          <div className='icon'>
            <img src={props.iconLocation} alt='Lokacija ikonica' />
          </div>
          <div className='content'>
            <span>Ramići bb</span>
            <span>78000 Banja Luka</span>
            <span>RS/BiH</span>
          </div>
        </li>
        <li>
          <div className='icon'>
            <img src={props.iconPhone} alt='Telefon ikonica' />
          </div>
          <div className='content'>
            <span>Tel/fax: +387 (0)51 354 252</span>
            <span>Mob: +387 (0)65 522 890</span>
          </div>
        </li>
        <li>
          <div className='icon'>
            <img src={props.iconEmail} alt='Email ikonica' />
          </div>
          <div className='content'>
            <span>E-mail: lakiplast@gmail.com</span>
          </div>
        </li>
      </ul>
      <div className='social'>
        {/*<a href="#" target="_blank" rel="noreferrer">
					<img src={props.iconFacebook} alt="Facebook ikonica" />
				</a>
				<a href="#" target="_blank" rel="noreferrer">
					<img src={props.iconInstagram} alt="Instagram ikonica" />
	</a>*/}
        <a href='whatsapp://38765203851' target='_blank' rel='noreferrer'>
          <img src={props.iconWhatsapp} alt='Whatsapp ikonica' />
        </a>
      </div>
    </div>
  );
}

export default Info;
