import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Info from '../info/Info';
import Map from '../map/Map';
import Location from '../../img/adresa-plavi.png';
import Phone from '../../img/telefon-plavi.png';
import Email from '../../img/email-plavi.png';
import Fb from '../../img/fb-plavi.png';
import In from '../../img/in-plavi.png';
import Wa from '../../img/whatup-plavi.png';
function Contact() {
	const [state, setState] = useState({
		name: '',
		phone: '',
		email: '',
		subject: '',
		msg: '',
	});
	const changeHandler = (ev) => {
		const target = ev.target;
		const name = ev.name;
		setState({
			...state,
			[name]: target.value,
		});
		console.log(state);
	};
	return (
		<>
			<Helmet>
				<title>Laki Plast - Kontakt</title>‍
				<meta name="description" content="Neki tekst za description" />
				<meta property="og:title" content="Laki Plast - Kontakt" />
				<meta property="og:description" content="Neki tekst za description" />
				<meta property="og:image" content="" />
				<meta property="og:url" content="lakiplast.com" />
				<meta property="og:site_name" content="Laki Plast - Kontakt" />
				<meta property="og:locale" content="sr_BA" />
				<meta property="og:type" content="website" />
			</Helmet>
			<div className="contact">
				<div className="container">
					<h1>Kontaktirajte nas</h1>
					<div className="contact-container">
						<div className="contact-form">
							<form action="">
								<div className="contact-item">
									<input
										type="text"
										name="name"
										value={state.name}
										placeholder="Ime:"
										onChange={changeHandler}
									/>
								</div>
								<div className="contact-item">
									<input
										type="tel"
										name="phone"
										value={state.phone}
										placeholder="Telefon:"
										onChange={changeHandler}
									/>
								</div>
								<div className="contact-item">
									<input
										type="email"
										name="email"
										value={state.email}
										placeholder="Vaša e-mail adresa:"
										onChange={changeHandler}
									/>
								</div>
								<div className="contact-item">
									<input
										type="text"
										name="subject"
										value={state.subject}
										placeholder="Predmet:"
										onChange={changeHandler}
									/>
								</div>
								<div className="contact-item">
									<textarea
										name=""
										value={state.msg}
										placeholder="Poruka:"
										onChange={changeHandler}
									></textarea>
								</div>
								<div className="btn-group">
									<button type="submit" className="btn">
										Pošalji
									</button>
								</div>
							</form>
						</div>
						<Info
							classVal="contact-info"
							iconLocation={Location}
							iconPhone={Phone}
							iconEmail={Email}
							iconFacebook={Fb}
							iconInstagram={In}
							iconWhatsapp={Wa}
						/>
					</div>
				</div>
				<Map />
			</div>
		</>
	);
}

export default Contact;
